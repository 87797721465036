import React, { useState, useRef, useEffect } from 'react';
import { Settings as SettingsIcon, User, Sliders, HelpCircle, X } from 'lucide-react';

const Settings = ({ isCollapsed = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => setIsOpen(!isOpen);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      <button 
        onClick={toggleMenu} 
        className={`flex items-center text-gray-300 hover:text-blue-300 ${isCollapsed ? 'w-8 h-8 justify-center' : ''}`}
      >
        <SettingsIcon size={20} />
        {!isCollapsed && <span className="ml-2">Settings</span>}
      </button>

      {isOpen && (
        <div 
          ref={menuRef}
          className={`absolute ${isCollapsed ? 'left-full bottom-0' : 'bottom-full left-0'} mb-2 w-64 bg-gray-800 rounded-lg shadow-xl p-4 border border-gray-700`}
          style={{ 
            minWidth: '200px',
            boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
          }}
        >
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold text-blue-300">Settings</h3>
            <button onClick={toggleMenu} className="text-gray-400 hover:text-white">
              <X size={20} />
            </button>
          </div>
          <ul>
            <li className="mb-2">
              <button className="w-full text-left flex items-center text-gray-300 hover:text-blue-300 py-2 px-3 rounded transition-colors duration-200 hover:bg-gray-700">
                <Sliders size={18} className="mr-2" />
                Settings
              </button>
            </li>
            <li className="mb-2">
              <button className="w-full text-left flex items-center text-gray-300 hover:text-blue-300 py-2 px-3 rounded transition-colors duration-200 hover:bg-gray-700">
                <User size={18} className="mr-2" />
                Profile
              </button>
            </li>
            <li>
              <button className="w-full text-left flex items-center text-gray-300 hover:text-blue-300 py-2 px-3 rounded transition-colors duration-200 hover:bg-gray-700">
                <HelpCircle size={18} className="mr-2" />
                Help
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Settings;