import React from 'react';

const ChatInterface = ({ chatMessages, inputMessage, setInputMessage, handleSendMessage }) => (
  <>
    <div className="flex-grow overflow-y-auto mb-4 bg-gray-800 rounded shadow p-4">
      {chatMessages.map((msg, index) => (
        <div key={index} className={`mb-2 ${msg.sender === 'user' ? 'text-right' : 'text-left'}`}>
          <span className={`inline-block p-2 rounded ${msg.sender === 'user' ? 'bg-blue-600 text-white' : 'bg-gray-600 text-gray-200'}`}>
            {msg.text}
          </span>
        </div>
      ))}
    </div>
    <form onSubmit={handleSendMessage} className="flex">
      <input
        type="text"
        value={inputMessage}
        onChange={(e) => setInputMessage(e.target.value)}
        className="flex-grow mr-2 p-2 border rounded bg-gray-600 text-gray-200 border-gray-500 focus:outline-none focus:border-blue-500"
        placeholder="Type your message..."
      />
      <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300">
        Send
      </button>
    </form>
  </>
);

export default ChatInterface;