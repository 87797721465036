import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import { ChevronLeft, ChevronRight, Star, Home } from 'lucide-react';
import QueryHistory from './components/QueryHistory';
import SuggestedQueries from './components/SuggestedQueries';
import Settings from './components/Settings';
import KeyTiles from './components/KeyTiles';
import ChatInterface from './components/ChatInterface';
import DataElements from './components/DataElements';

function App() {
  const [isLeftColumnVisible, setIsLeftColumnVisible] = useState(true);
  const [inputMessage, setInputMessage] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [variables, setVariables] = useState({ gdp: 2.5, inflation: 1.8, unemployment: 3.6 });
  const [summary, setSummary] = useState('This is a placeholder for the economic summary.');

  const toggleLeftColumn = () => {
    setIsLeftColumnVisible(!isLeftColumnVisible);
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (inputMessage.trim()) {
      setChatMessages([...chatMessages, { text: inputMessage, sender: 'user' }]);
      setInputMessage('');
      // Here you would typically send the message to your backend and get a response
    }
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/app" element={
          <div className="flex h-screen bg-gray-900">
            {/* Left Column */}
            <div className={`${isLeftColumnVisible ? 'w-72' : 'w-12'} bg-gray-800 flex flex-col h-screen transition-all duration-300 ease-in-out`}>
              <div className="flex items-center justify-between p-4">
                {isLeftColumnVisible && (
                  <Link to="/" className="text-3xl font-bold text-blue-400 glisten-effect">
                    juppiter
                  </Link>
                )}
                <button 
                  onClick={toggleLeftColumn}
                  className="text-gray-300 hover:text-blue-300 focus:outline-none"
                >
                  {isLeftColumnVisible ? <ChevronLeft size={24} /> : <ChevronRight size={24} />}
                </button>
              </div>
              {isLeftColumnVisible ? (
                <div className="p-4 pt-0 flex-grow flex flex-col">
                  <QueryHistory />
                  <SuggestedQueries />
                  <div className="mt-auto">
                    {/* Subscribe button */}
                    <button className="group inline-flex items-center justify-center bg-[#2196f3] hover:bg-[#00bcd4] text-white font-bold py-2 px-4 rounded mb-2 mt-4 transition-all duration-300 w-full">
                      <Star className="mr-2 transition-transform duration-300 group-hover:animate-spin" size={20} />
                      Subscribe
                    </button>
                    <Settings />
                    {/* Return to Homepage link */}
                    <Link to="/" className="text-xs text-gray-400 hover:text-blue-300 transition-colors duration-200 block mt-1">
                      Return to Homepage
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="flex-grow flex flex-col justify-end p-2">
                  <button className="group flex items-center justify-center bg-[#2196f3] hover:bg-[#00bcd4] text-white rounded w-8 h-8 mb-2 transition-all duration-300">
                    <Star className="transition-transform duration-300 group-hover:animate-spin" size={20} />
                  </button>
                  <div className="relative">
                    <Settings isCollapsed={true} />
                  </div>
                  <Link to="/" className="flex items-center justify-center text-gray-300 hover:text-blue-300 w-8 h-8 mt-2">
                    <Home size={20} />
                  </Link>
                </div>
              )}
            </div>

            {/* Middle Column */}
            <div className="flex-grow min-w-[400px] bg-gray-700 p-4 flex flex-col text-gray-200">
              <KeyTiles variables={variables} summary={summary} />
              <ChatInterface 
                chatMessages={chatMessages}
                inputMessage={inputMessage}
                setInputMessage={setInputMessage}
                handleSendMessage={handleSendMessage}
              />
            </div>

            {/* Right Column */}
            <div className="w-1/3 bg-gray-800 p-4 text-gray-300 overflow-y-auto">
              <DataElements />
            </div>
          </div>
        } />
      </Routes>

      <style jsx>{`
        @keyframes shine {
          0% { background-position: -200px; }
          100% { background-position: 200px; }
        }
        .glisten-effect {
          color: #60a5fa;
          transition: color 0.3s ease;
        }
        .glisten-effect:hover {
          color: #93c5fd;
        }
      `}</style>
    </Router>
  );
}

export default App;