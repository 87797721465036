import React from 'react';

const SuggestedQueries = () => (
  <div className="mb-4">
    <h3 className="text-sm font-semibold mb-2 text-blue-300">Suggested Queries</h3>
    <div className="grid grid-cols-2 gap-2">
      {[1, 2, 3, 4].map((num) => (
        <div 
          key={num} 
          className="bg-gray-700 p-2 rounded cursor-pointer hover:bg-gray-600 transition-colors duration-200 h-24 flex items-center justify-center"
        >
          <p className="text-xs text-gray-300 text-center">Suggested Query {num}</p>
        </div>
      ))}
    </div>
  </div>
);

export default SuggestedQueries;