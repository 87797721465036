import React from 'react';

const QueryHistory = () => (
  <div className="flex-grow overflow-y-auto">
    <h2 className="text-lg font-semibold mb-2 text-blue-300">Macro Queries</h2>
    <ul className="text-gray-300">
      <li className="mb-2">Previous query 1</li>
      <li className="mb-2">Previous query 2</li>
      <li className="mb-2">Previous query 3</li>
    </ul>
  </div>
);

export default QueryHistory;