import React from 'react';
import { BarChart2 } from 'lucide-react';

const DataElements = () => (
  <>
    <h2 className="text-lg font-semibold mb-4 text-blue-300 sticky top-0 bg-gray-800 pb-2">Visualizations & Data</h2>
    {[1, 2, 3, 4, 5, 6].map((num) => (
      <div key={num} className="bg-gray-700 h-64 mb-4 flex items-center justify-center rounded">
        <BarChart2 size={48} className="text-blue-400" />
        <p className="ml-2">Chart {num}</p>
      </div>
    ))}
  </>
);

export default DataElements;