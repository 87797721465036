import React from 'react';

const KeyTiles = ({ variables, summary }) => (
  <>
    <div className="grid grid-cols-3 gap-4 mb-4">
      <div className="bg-gray-800 p-4 rounded shadow">
        <h3 className="font-semibold text-blue-300">GDP</h3>
        <p>{variables.gdp.toFixed(2)}%</p>
      </div>
      <div className="bg-gray-800 p-4 rounded shadow">
        <h3 className="font-semibold text-blue-300">Inflation</h3>
        <p>{variables.inflation.toFixed(2)}%</p>
      </div>
      <div className="bg-gray-800 p-4 rounded shadow">
        <h3 className="font-semibold text-blue-300">Unemployment</h3>
        <p>{variables.unemployment.toFixed(2)}%</p>
      </div>
    </div>
    <div className="bg-gray-800 p-4 rounded shadow mb-4">
      <h3 className="font-semibold mb-2 text-blue-300">Summary</h3>
      <p className="text-sm">{summary}</p>
    </div>
  </>
);

export default KeyTiles;