import React, { useEffect, useRef } from 'react';

const NodeBackground = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;
    let nodes = [];

    // Set canvas size
    const resizeCanvas = () => {
      const oldWidth = canvas.width;
      const oldHeight = canvas.height;
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      // Adjust node positions when resizing
      const scaleX = canvas.width / oldWidth;
      const scaleY = canvas.height / oldHeight;
      nodes.forEach(node => {
        node.x *= scaleX;
        node.y *= scaleY;
      });
    };

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    // Node class (keep your existing Node class implementation)
    class Node {
      constructor(x, y) {
        this.x = x;
        this.y = y;
        this.radius = Math.random() * 3 + 5;
        this.vx = (Math.random() - 0.5) * 0.2;
        this.vy = (Math.random() - 0.5) * 0.2;
        this.pulseIntensity = 0;
        this.pulseSpeed = Math.random() * 0.0002 + 0.0001;
      }

      draw() {
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
        ctx.fillStyle = `rgba(100, 100, 100, ${0.3 + this.pulseIntensity * 0.4})`;
        ctx.fill();
      }

      update() {
        this.pulseIntensity = Math.sin(Date.now() * this.pulseSpeed) * 0.5 + 0.5;
        
        // Move the node
        this.x += this.vx;
        this.y += this.vy;

        // Wrap around edges
        if (this.x < 0) this.x = canvas.width;
        if (this.x > canvas.width) this.x = 0;
        if (this.y < 0) this.y = canvas.height;
        if (this.y > canvas.height) this.y = 0;
      }
    }

    // Create nodes
    const createNodes = () => {
      const nodeCount = 75;
      const scaleFactor = 1.5; // Assume a larger initial viewport
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;
      const maxDistance = Math.min(canvas.width, canvas.height) / 2 * scaleFactor;

      for (let i = 0; i < nodeCount; i++) {
        const angle = Math.random() * 2 * Math.PI;
        const distance = Math.random() * maxDistance;
        const x = centerX + distance * Math.cos(angle);
        const y = centerY + distance * Math.sin(angle);
        nodes.push(new Node(x, y));
      }
    };

    createNodes();

    // Animation loop
    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Draw connections
      ctx.strokeStyle = 'rgba(100, 100, 100, 0.1)';
      ctx.lineWidth = 1;
      for (let i = 0; i < nodes.length; i++) {
        for (let j = i + 1; j < nodes.length; j++) {
          const dx = nodes[i].x - nodes[j].x;
          const dy = nodes[i].y - nodes[j].y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          
          if (distance < canvas.width / 3) { // Increased connection distance
            const opacity = (1 - distance / (canvas.width / 3)) * 0.2;
            ctx.strokeStyle = `rgba(100, 149, 237, ${opacity})`;
            ctx.beginPath();
            ctx.moveTo(nodes[i].x, nodes[i].y);
            ctx.lineTo(nodes[j].x, nodes[j].y);
            ctx.stroke();
          }
        }
      }

      // Update and draw nodes
      nodes.forEach(node => {
        node.update();
        node.draw();
      });

      animationFrameId = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return <canvas ref={canvasRef} className="absolute top-0 left-0 w-full h-full" />;
};

export default NodeBackground;